














































































































































































































































































































































































































































































































































































.info-list-c:last-child{
  span{
    display: none;
  }
}
.block-user{
  display: flex;
  align-items: center;
  .image{
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .name{
    padding-left: 8px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &-wrapper{
    .block-user{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.more-info{
  &-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-in{
      margin-right: 15px;
    }
  }
  &-layout{
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    padding: 16px;
  }
 }
.table.table-mod{
  tr:first-child{
    td{
      padding-top: 0;
    }
  }
  tr:last-child{
    td{
      padding-bottom: 0;
    }
  }
}
.info-user-wrapper{
  display: flex;
  .info{
    width: 100%;
    padding-right: 40px;
  }
  .image{
    width: 120px;
    height: 120px;
    flex: 0 0 120px;
    border-radius: 8px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
.thumbnail-supp{
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 16px;
  margin-bottom: 4px;
  .head{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .status{
    position: relative;
    padding-left: 12px;
    max-width: 40%;
    margin-right: 15px;
    &:after{
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: #161616;
    }
  }
  .status,
  .time{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &.green{
    .status{
      &:after{
        background-color: #02BB9F;
      }
    }
  }
  &.red{
    .status{
      &:after{
        background-color: #FA665B;
      }
    }
  }
}
.thumbnail-stepper{
  padding-left: 18px;
  position: relative;
  padding-bottom: 24px;
  &:after{
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    position: absolute;
    left: 0;
    bottom: -5px;
  }
  &:before{
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #5E4BF1;
    position: absolute;
    left: 0;
    top: 5px;
    transform: translateX(-50%);
    z-index: 2;
  }
  .title{
    margin-bottom: 8px;
  }
  &:last-child{
    padding-bottom: 0;
    &:after{
      display: none;
    }
  }
}
