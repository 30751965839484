/*==========  Thumbnail-user Style  ==========*/

.thumbnail-user-head{
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
}
.thumbnail-user{
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  &:last-child{
    margin-bottom: 0;
  }
  .check{
    line-height: 0;
  }
  .info-more{
    margin-left: auto;
  }
  .assign-inner{
    display: flex;
    align-items: center;
    .image{
      width: 24px;
      flex: 0 0 24px;
      height: 24px;
      margin-right: 6px;
      border-radius: 50%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name-in{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .assign-wrapper{
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    //overflow-x: auto;
    max-width: 180px;
    .assign-inner{
      margin-right: 6px;
      .image{
        margin-right: 0;
      }
    }
  }
  .check,
  .name,
  .location{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.thumbnail-user-head,
.thumbnail-user{
  .check{
    width: 100%;
    max-width: 22px;
    margin-right: 10px;
    line-height: 0;
  }
  .name{
    width: 100%;
    max-width: 16%;
    margin-right: 10px;
  }
  .location{
    width: 100%;
    max-width: 16%;
    margin-right: 10px;
  }
  .account{
    width: 100%;
    max-width: 8%;
    margin-right: 10px;
  }
  .role{
    width: 100%;
    max-width: 8%;
    margin-right: 10px;
  }
  .assign{
    width: 100%;
    max-width: 14%;
    margin-right: 10px;
  }
  .activity{
    width: 100%;
    max-width: 15%;
    margin-right: 10px;
  }
}

/*==========  Thumbnail-nw-user Style  ==========*/

.thumbnail-user-nw-head{
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
}
.thumbnail-user-nw{
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  &:last-child{
    margin-bottom: 0;
  }
  .check{
    line-height: 0;
  }
  .info-more{
    margin-left: auto;
  }
  .assign-inner{
    display: flex;
    align-items: center;
    .image{
      width: 24px;
      flex: 0 0 24px;
      height: 24px;
      margin-right: 6px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name-in{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .check,
  .name,
  .location{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.thumbnail-user-nw-head,
.thumbnail-user-nw{
  .check{
    width: 100%;
    max-width: 22px;
    margin-right: 10px;
    line-height: 0;
  }
  .name{
    width: 100%;
    max-width: 18%;
    margin-right: 10px;
  }
  .role{
    width: 100%;
    max-width: 18%;
    margin-right: 10px;
  }
  .location{
    width: 100%;
    max-width: 18%;
    margin-right: 10px;
  }
  .languages{
    width: 100%;
    max-width: 18%;
    margin-right: 10px;
  }
  .certificates{
    width: 100%;
    max-width: 18%;
    margin-right: 10px;
  }
}

/*==========  Hover  ==========*/

// @media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




