








































































































































































































































































.more-info-edit{
  &-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-in{
      margin-right: 15px;
    }
  }
}
