











































































































.line-chart-shadow{
  max-width: 180px;
  position: relative;
  height: 40px;
  position: relative;
  overflow: hidden;
  &:after{
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    top: 0;
    box-shadow: 0px 0px 38px 40px rgb(255 255 255 / 90%);
    z-index: 2;
  }
  &:before{
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 0;
    right: 0;
    top: 0;
    box-shadow: 0px 0px 38px 40px rgb(255 255 255 / 90%);
    z-index: 2;
  }
  > div{
    position: relative;
    height: 40px;
  }
}
